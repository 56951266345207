import React, {Component} from "react";
import {connect} from "react-redux"
import TableGrid from "../../../components/basics/table-grid/TableGrid";
import {getAnimalNo1ByAnmID, getDateString, getPinnedRowSum} from "../../../utils/RaportsUtils";
import {convertWeightUnitTo, getUnit} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import {FilterTypes} from "../../../constans/filter";
import _ from "lodash";
import Card from "../../../components/basics/card/Card";
import {withTranslation} from "react-i18next";

export class SeparationReport extends Component {

    state = {
        data: []
    };

    constructor(props) {
        super(props);

        const {match: {params: {id}}} = this.props;
        console.log(id);
        let report = this.props.reports.find(item => item.FeturaQuery.FeturaQTime === +id);
        console.log(report);
        if (report) {
            this.state = {
                data: report.data
            }
        }
    }

    weightFormatter(value) {
        return _.isNil(value) ? undefined : convertWeightUnitTo(value, {
            showUnit: false,
            rawValue: true,
            unit: UnitTypes.MEDIUM,
            fixed: 1
        });
    }

    render() {
        const {t} = this.props;
        const {data} = this.state;
        const headers = [
            {
                name: t("newReports.separation.pigNo"),
                field: "anmNo",
                filterType: FilterTypes.STRING
            },
            {
                name: t("newReports.separation.separationDate"),
                valueFormatter: (value) => getDateString(value),
                excelFormatter: (value) => new Date(value),
                field: "separationDate",
                filterType: FilterTypes.DATE,
                disableValueFormatterSort: true
            },
            {
                name: t("newReports.separation.piCnt"),
                field: "piCnt",
                filterType: FilterTypes.NUMBER
            },
            {
                name: `${t("newReports.separation.piWeight")} (${getUnit("weight", UnitTypes.MEDIUM)})`,
                valueFormatter: this.weightFormatter,
                excelFormatter: this.weightFormatter,
                field: "piWeight",
                filterType: FilterTypes.NUMBER
            },
            {
                name: t("newGroupNumber"),
                valueFormatter: value => getAnimalNo1ByAnmID(value),
                excelFormatter: value => getAnimalNo1ByAnmID(value),
                field: "ChildAnmID",
                filterType: FilterTypes.STRING
            }
        ];
        return (
            <Card>
                <TableGrid data={data} headers={headers}
                           bottomPinnedRows={(data) => {
                               let piCnt = getPinnedRowSum(data, "piCnt");
                               let piWeight = getPinnedRowSum(data, "piWeight");
                               return data.length ? [
                                   {
                                       anmNo: t("avg"),
                                       piCnt: piCnt / data.length,
                                       piWeight: this.weightFormatter(piWeight / data.length),
                                   },
                                   {
                                       anmNo: t("newReports.separation.total"),
                                       piCnt: piCnt,
                                       piWeight: this.weightFormatter(piWeight),
                                   }
                               ] : []
                           }}
                           saveToExcel={true}
                           isSortable={true}
                           shouldIndex={true}
                           showFilter={true}
                           excelFileName={"Raport - Separation"}
                           initialSortColumn={headers[0].name}
                />
            </Card>
        )
    }
}

SeparationReport = connect((state) => ({
    farm: state.location.farm,
    reports: state.raports.reports
}))(SeparationReport);

export default withTranslation()(SeparationReport);