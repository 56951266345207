import React, {Component} from "react";
import {connect} from "react-redux"
import TableGrid from "../../../components/basics/table-grid/TableGrid";
import {FilterTypes} from "../../../constans/filter";
import HerdStructureChart from "../../../components/charts/HerdStructureChart";
import {Col, Row} from "react-bootstrap";
import {getTechnologyGroupSize, getTechnologyGroupWeeks} from "../../../utils/SettingsUtils";
import Card from "../../../components/basics/card/Card";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class HerdStructureReport extends Component {

    state = {
        data: []
    };

    constructor(props) {
        super(props);

        const {match: {params: {id}}} = this.props;
        let report = this.props.reports.find(item => item.FeturaQuery.FeturaQTime === +id);
        if (report) {
            this.state = {
                data: report.data
            }
        }
    }

    render() {
        const {t} = this.props;
        const {data} = this.state;
        const headers = [
            {
                name: t("lactation"),
                field: "lactation",
                filterType: FilterTypes.NUMBER
            },
            {
                name: t("amountSows"),
                field: "sowsCnt",
                filterType: FilterTypes.NUMBER
            }
        ];
        return (
            <Card>
                <Row>
                    <Col xs={12}>
                        {t("newReports.structure.technologyGroupSize")}: <strong>{getTechnologyGroupSize()}</strong><span
                        className={"ml-1 opacity-75"}>{i18next.t("newReports.structure.XweekCycle", {count: getTechnologyGroupWeeks()})}</span>
                    </Col>
                    <Col lg={4}>
                        <TableGrid data={data} headers={headers}
                                   saveToExcel={true}
                                   isSortable={true}
                                   shouldIndex={true}
                                   showFilter={true}
                                   excelFileName={"Raport - Herd Structure"}
                                   initialSortColumn={headers[0].name}
                        />
                    </Col>
                    <Col lg={8}>
                        <HerdStructureChart data={data}/>
                    </Col>
                </Row>

            </Card>
        )
    }
}

HerdStructureReport = connect((state) => ({
    farm: state.location.farm,
    reports: state.raports.reports
}))(HerdStructureReport);
// HerdStructureRaport = withRoles({roles: [Roles._BREEDING], showComponent: true})(HerdStructureRaport);
export default withTranslation()(HerdStructureReport);