import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withReport} from "../withReport";
import Card from "../../../../components/basics/card/Card";
import LoadingComponent from "../../../../components/loading/LoadingComponent";
import InfoBox from "../../../../components/basics/info-box/InfoBox";
import TableGrid from "../../../../components/basics/table-grid/TableGrid";
import NotFound from "../../../../components/NotFound";
import animalsDB from "../../../../database/animalsDB";
import {FilterTypes} from "../../../../constans/filter";
import {convertWeightUnitTo} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";
import ForageForAnimalFullWidthRow from "./ForageForAnimalFullWidthRow";
import settingsDB from "../../../../database/settingsDB";
import {withTranslation} from "react-i18next";
import {isUsingFakeData} from "../../../../utils/SettingsUtils";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
    };
}

class ForageForAnimalReport extends Component {

    animalValueFormatter = value => {
        if (isUsingFakeData()) return value;
        let animal = animalsDB.getAnimalById(value);
        if (animal) return animal.AnmNo1;
    };

    animalRFIDValueFormatter = value => {
        if (isUsingFakeData()) return "";
        let animal = animalsDB.getAnimalById(value);
        if (animal) return animal.RFID;
    };

    weightValueFormatter = value => {
        if (isUsingFakeData()) return value;
        if (value) return convertWeightUnitTo(+value, {
            unit: UnitTypes.MEDIUM,
            showUnit: true
        })
    };

    foragesValueFormatter = value => {
        if (isUsingFakeData()) return value;
        let names = value.map(item => {
            let forage = settingsDB.getSettingByID(item.fid, {showDeleted: true});
            if (forage) return forage.SetData.Name;
            return "?";
        });
        return names.join(" ");
    };

    bottomPinnedRows = paginatedData => {
        let forages = {};
        for (let row of paginatedData) {
            for (let forage of row.forages) {
                if (!forages[forage.fid]) forages[forage.fid] = {total_amount: 0, total_amountofday: 0};
                forages[forage.fid].total_amount += +forage.amount;
                forages[forage.fid].total_amountofday += +forage.amountofday;
            }
        }
        let totalAmount = 0;
        let totalWorkDay = 0;
        let array = Object.keys(forages).map((key, index) => {
            totalAmount += forages[key].total_amount;
            totalWorkDay += forages[key].total_amountofday;
            if (index === 0) return (
                <div className="table-row">
                    <div className="table-item index"/>
                    <div className="table-item">
                        {this.props.t("newReports.dispensersUsage.sum")}
                    </div>
                    <div className="table-item"/>
                    <div className="table-item">
                        {this.foragesValueFormatter([{fid: key}])}
                    </div>
                    <div className="table-item">
                        {this.weightValueFormatter(forages[key].total_amount)}
                    </div>
                    <div className="table-item">
                        {forages[key].total_amountofday}
                    </div>
                </div>
            )
            return {
                forages: this.foragesValueFormatter([{fid: key}]),
                ...forages[key],
                total_amount: this.weightValueFormatter(forages[key].total_amount)
            }
        });
        array.push({
            forages: this.props.t("all"),
            total_amount: this.weightValueFormatter(totalAmount),
            total_amountofday: totalWorkDay
        })
        return array;
    }

    render() {
        const {loading, report, error, data, t} = this.props;
        if (!report) return <Card><NotFound/></Card>;
        const headers = [
            {
                name: t("newReports.forageForAnimal.animal"),
                field: "anmid",
                valueFormatter: this.animalValueFormatter,
                filterType: FilterTypes.NUMBER,
                excelFormatter: this.animalValueFormatter
            },
            {
                name: "RFID",
                field: "anmid",
                valueFormatter: this.animalRFIDValueFormatter,
                filterType: FilterTypes.NUMBER,
                filterColumn: "RFID",
                excelFormatter: this.animalRFIDValueFormatter
            },
            {
                name: t("forage"),
                field: "forages",
                valueFormatter: this.foragesValueFormatter,
                excelFormatter: this.foragesValueFormatter
            },
            {
                name: t("forageAmount"),
                field: "total_amount",
                valueFormatter: this.weightValueFormatter,
                filterType: FilterTypes.NUMBER,
                excelFormatter: this.weightValueFormatter
            },
            {
                name: t("numberOfDays"),
                field: "total_amountofday",
                filterType: FilterTypes.NUMBER
            }
        ];
        return (
            <Card style={loading ? {minHeight: "10rem"} : null}>
                <LoadingComponent isLoading={loading}/>
                {
                    error &&
                    <InfoBox boxColor={"error"}>
                        <div>
                            {t("newReports.error")}
                        </div>
                        <div>
                            {error}
                        </div>
                    </InfoBox>
                }
                {
                    !error &&
                    <>
                        <TableGrid data={data} headers={headers} shouldIndex showFilter isFullWidthRow={() => true}
                                   fullWidthRow={<ForageForAnimalFullWidthRow
                                       animalValueFormatter={this.animalValueFormatter}
                                       weightValueFormatter={this.weightValueFormatter}
                                       rfidValueFormatter={this.animalRFIDValueFormatter}/>} saveToExcel
                                   excelFileName={"forageForAnimal"}
                                   initialSortColumn={headers[0].name}
                                   bottomPinnedRows={!isUsingFakeData() && this.bottomPinnedRows}/>
                    </>
                }
            </Card>
        )
    }
}

ForageForAnimalReport = withTranslation()(ForageForAnimalReport);
ForageForAnimalReport = withReport()(ForageForAnimalReport);

export default connect(
    mapStateToProps,
)(ForageForAnimalReport);

