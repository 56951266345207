import React from "react";
import TableGrid from "../../../components/basics/table-grid/TableGrid";
import {FilterTypes} from "../../../constans/filter";
import {connect} from "react-redux";
import {getMedicineNameByMedicineID} from "../../../utils/RaportsUtils";
import Card from "../../../components/basics/card/Card";
import {withTranslation} from "react-i18next";

class MedicineConsumptionReport extends React.Component {

    state = {
        data: []
    };

    constructor(props) {
        super(props);

        const {match: {params: {id}}} = this.props;
        let report = this.props.reports.find(item => item.FeturaQuery.FeturaQTime === +id);
        if (report) {
            this.state = {
                data: report.data
            }
        }
    }

    render() {
        const {t} = this.props;
        const {data} = this.state;
        const headers = [
            {
                name: t("medicine"),
                field: "Medicine",
                valueFormatter: value => getMedicineNameByMedicineID(value),
                excelFormatter: value => getMedicineNameByMedicineID(value),
                filterType: FilterTypes.STRING
            },
            {
                name: t("newReports.medicineConsumption.consumption"),
                field: "consumption",
                filterType: FilterTypes.NUMBER
            }
        ];

        return (
            <Card>
                <TableGrid
                    data={data}
                    headers={headers}
                    saveToExcel={true}
                    isSortable={true}
                    shouldIndex={true}
                    showFilter={true}
                    excelFileName={"Report - Medicine"}
                    initialSortColumn={headers[0].name}
                />
            </Card>
        )
    }
}

MedicineConsumptionReport = connect((state) => ({
    farm: state.location.farm,
    reports: state.raports.reports,
    user: state.user,
}))(MedicineConsumptionReport);

export default withTranslation()(MedicineConsumptionReport);