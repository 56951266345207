import React, {Component} from "react";
import {connect} from "react-redux"
import TableGrid from "../../../components/basics/table-grid/TableGrid";
import {getLocationPathByPlcmntID, getPinnedRowSum} from "../../../utils/RaportsUtils";
import {FilterTypes} from "../../../constans/filter";
import {sortAsNumber} from "../../../utils/SortUtils";
import Card from "../../../components/basics/card/Card";
import {withTranslation} from "react-i18next";

export class IdleDaysReport extends Component {

    state = {
        data: []
    };

    constructor(props) {
        super(props);

        const {match: {params: {id}}} = this.props;
        let report = this.props.reports.find(item => item.FeturaQuery.FeturaQTime === +id);
        if (report) {
            this.state = {
                data: report.data
            }
        }
    }

    rowClassName = object => {
        if (object.idleDays < 0) return "error";
    };

    render() {
        const {t} = this.props;
        const {data} = this.state;
        const headers = [
            {
                name: t("newReports.idle.pigNo"),
                field: "AnmNo1",
                filterType: FilterTypes.STRING,
                customSort: sortAsNumber
            },
            {
                name: t("newReports.idle.idleDay"),
                field: "idleDays",
                valueFormatter: (value) => Math.round(value),
                filterType: FilterTypes.NUMBER
            },
            {
                name: t("newReports.idle.repetition"),
                field: "repetition",
                valueFormatter: (value) => Math.round(value),
                filterType: FilterTypes.NUMBER
            },
            {
                name: t("newReports.idle.wasMommy"),
                field: "wasMommy",
                valueFormatter: (value) => Math.round(value),
                filterType: FilterTypes.NUMBER
            },
            {
                name: t("location"),
                field: "location",
                valueFormatter: (value) => getLocationPathByPlcmntID(value),
                excelFormatter: (value) => getLocationPathByPlcmntID(value),
                filterType: FilterTypes.LOCATION
            }
        ];

        return (
            <Card>
                <TableGrid data={data} headers={headers}
                           bottomPinnedRows={(data) => {
                               return data.length ? [
                                   {
                                       repetition: (getPinnedRowSum(data, "repetition") / data.length).toFixed(2),
                                       wasMommy: (getPinnedRowSum(data, "wasMommy") / data.length).toFixed(2),
                                       idleDays: (getPinnedRowSum(data, "idleDays") / data.length).toFixed(2),
                                       AnmNo1: t("avg"),
                                   }
                               ] : []
                           }}
                           rowClassName={this.rowClassName}
                           saveToExcel={true}
                           isSortable={true}
                           shouldIndex={true}
                           showFilter={true}
                           excelFileName={"Raport - Idle Days"}
                           initialSortColumn={headers[0].name}
                />
            </Card>
        )
    }
}

IdleDaysReport = connect((state) => ({
    farm: state.location.farm,
    reports: state.raports.reports
}))(IdleDaysReport);
export default withTranslation()(IdleDaysReport);