import React, {Component} from "react";
import {connect} from "react-redux"
import TableGrid from "../../../components/basics/table-grid/TableGrid";
import {FilterTypes} from "../../../constans/filter";
import {
    getAnimalNo1ByAnmID,
    getDateString,
    getLocationPathByPlcmntID,
    getSelectionReasonByID
} from "../../../utils/RaportsUtils";
import Card from "../../../components/basics/card/Card";
import {withTranslation} from "react-i18next";

export class SelectionReport extends Component {

    state = {
        data: []
    };

    constructor(props) {
        super(props);

        const {match: {params: {id}}} = this.props;
        let report = this.props.reports.find(item => item.FeturaQuery.FeturaQTime === +id);
        if (report) {
            this.state = {
                data: report.data
            }
        }
    }

    render() {
        const {t} = this.props;
        const {data} = this.state;

        const headers = [
            {
                name: t("animalNumber"),
                field: "AnmID",
                valueFormatter: (value) => getAnimalNo1ByAnmID(value),
                excelFormatter: (value) => getAnimalNo1ByAnmID(value),
                filterType: FilterTypes.STRING
            },
            {
                name: t("newReports.selection.selectionDate"),
                field: "EvTime",
                valueFormatter: (value) => getDateString(value),
                excelFormatter: (value) => new Date(value),
                filterType: FilterTypes.DATE,
                disableValueFormatterSort: true
            },
            {
                name: t("newReports.selection.selectionReason"),
                field: "EvData.Reasn",
                valueFormatter: (value) => getSelectionReasonByID(value),
                excelFormatter: (value) => getSelectionReasonByID(value),
                filterType: FilterTypes.STRING
            },
            {
                name: t("newReports.selection.location"),
                field: "EvData.PlcmntID",
                valueFormatter: value => getLocationPathByPlcmntID(value),
                excelFormatter: value => getLocationPathByPlcmntID(value),
                filterType: FilterTypes.LOCATION
            },
            {
                name: t("comment"),
                field: "Comment",
                valueFormatter: value => value || "-",
                excelFormatter: value => value || "-",
                filterType: FilterTypes.STRING
            },
        ];

        return (
            <Card>
                <TableGrid data={data} headers={headers}
                           bottomPinnedRows={(currentData) => currentData.length ? [
                               {
                                   "EvData.PlcmntID": t("newReports.selection.total"),
                                   Comment: currentData.length
                               }
                           ] : []}
                           saveToExcel={true}
                           isSortable={true}
                           shouldIndex={true}
                           showFilter={true}
                           excelFileName={"Raport - Selections"}
                           initialSortColumn={headers[0].name}
                />
            </Card>
        )
    }
}

SelectionReport = connect((state) => ({
    farm: state.location.farm,
    reports: state.raports.reports
}))(SelectionReport);
// SelectionRaport = withRoles({roles: [Roles._BREEDING], showComponent: true})(SelectionRaport);
export default withTranslation()(SelectionReport);