import React, {Component} from 'react';
import {getReportData} from "../../../actions/raportsActions";
import {connect} from "react-redux";

export const withReport = props => (WrappedComponent) => {

    class WithReport extends Component {
        state = {
            report: null,
            loading: true,
            error: null,
            data: []
        };

        constructor(props) {
            super(props);

            const {match: {params: {id}}} = this.props;
            let report = this.props.reports.find(item => item.FeturaQuery.FeturaQTime === +id);
            this.state = {
                ...this.state,
                report
            }
        }

        async componentDidMount() {
            const {report} = this.state;
            if (report) {
                const {user} = this.props;
                try {
                    let data = await getReportData(report.LocalQTime, report.LocalUserID, user.ClientID, user.LocalUserID);
                    console.log(data);
                    this.setState({
                        loading: false,
                        data
                    });
                } catch (e) {
                    console.error("error", e);
                    this.setState({
                        error: e.message || e,
                        loading: false
                    })
                }
            }
        }

        render() {
            return <WrappedComponent {...this.props} {...this.state}/>
        }

    }

    WithReport.displayName = `WithReport(${WrappedComponent.displayName || WrappedComponent.name || "Component"})`;

    return connect(
        state => ({
            reports: state.raports.reports,
            user: state.user.user
        })
    )(WithReport);
}