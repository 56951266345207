import React, {Component} from "react";
import {connect} from "react-redux"
import TableGrid from "../../../components/basics/table-grid/TableGrid";
import {FilterTypes} from "../../../constans/filter";
import {getOperatorName, getPinnedRowSum} from "../../../utils/RaportsUtils";
import _ from "lodash";
import {sortAsNumber} from "../../../utils/SortUtils";
import Card from "../../../components/basics/card/Card";
import {withTranslation} from "react-i18next";
import {checkIfUserHasPrivilegedAccess} from "../../../utils/NewRolesUtils";

export class InseminationsReport extends Component {

    state = {
        data: []
    };

    constructor(props) {
        super(props);

        const {match: {params: {id}}} = this.props;
        let report = this.props.reports.find(item => item.FeturaQuery.FeturaQTime === +id);
        if (report) {
            this.state = {
                data: report.data
            }
        }
    }


    render() {
        const {t} = this.props;
        const {data} = this.state;
        const singleInseminatorsData = data.filter((item) => !_.isArray(item.inseminator));
        const headers = [
            {
                name: t("newReports.insemination.inseminator"),
                field: "inseminator",
                valueFormatter: (value) => {
                    const operators = _.isArray(value) ? value : [value];
                    return operators.map(OperID => getOperatorName(OperID)).join(",") || "?"
                },
                excelFormatter: (value) => {
                    const operators = _.isArray(value) ? value : [value];
                    return operators.map(OperID => getOperatorName(OperID)).join(",") || "?"
                },
                filterType: FilterTypes.STRING,
                customSort: sortAsNumber,
                shouldShow: () => checkIfUserHasPrivilegedAccess(),
            },
            {
                name: t("newReports.insemination.inseminationsCnt"),
                field: "inseminationsCnt",
                filterType: FilterTypes.NUMBER
            },
            {
                name: t("newReports.insemination.pendingCnt"),
                field: "pendingCnt",
                filterType: FilterTypes.NUMBER
            },
            {
                name: t("newReports.insemination.wellInseminatedCnt"),
                field: "wellInseminatedCnt",
                filterType: FilterTypes.NUMBER
            },
            {
                name: t("newReports.insemination.wrongInseminatedCnt"),
                field: "wrongInseminatedCnt",
                filterType: FilterTypes.NUMBER
            }
        ];

        return (
            <Card>
                <TableGrid data={data} headers={headers}
                           bottomPinnedRows={() => {
                               return singleInseminatorsData.length ? [
                                   {
                                       inseminationsCnt: getPinnedRowSum(singleInseminatorsData, "inseminationsCnt"),
                                       pendingCnt: getPinnedRowSum(singleInseminatorsData, "pendingCnt"),
                                       wellInseminatedCnt: getPinnedRowSum(singleInseminatorsData, "wellInseminatedCnt"),
                                       wrongInseminatedCnt: getPinnedRowSum(singleInseminatorsData, "wrongInseminatedCnt"),
                                       inseminator: t("newReports.insemination.total")
                                   }
                               ] : []
                           }}
                           saveToExcel={true}
                           isSortable={true}
                           shouldIndex={true}
                           showFilter={true}
                           excelFileName={"Raport - Inseminations"}
                           initialSortColumn={headers[0].name}
                />
            </Card>
        )
    }
}

InseminationsReport = connect((state) => ({
    farm: state.location.farm,
    reports: state.raports.reports
}))(InseminationsReport);

export default withTranslation()(InseminationsReport);
