import React from "react";
import {connect} from "react-redux";
import Header from "../../components/basics/header/Header";
import ViewContainer from "../../components/basics/view-container/ViewContainer";
import {Link, Route, Switch, withRouter} from "react-router-dom";
import SelectionReport from "./reports/SelectionReport";
import IdleDaysReport from "./reports/IdleDaysReport";
import SalesReport from "./reports/SalesReport";
import DeadReport from "./reports/DeadReport";
import TreatmentReport from "./reports/TreatmentReport";
import HistoryBirthReport from "./reports/HistoryBirthReport";
import HerdStructureReport from "./reports/HerdStructureReport";
import InseminationsReport from "./reports/InseminationsReport";
import SeparationReport from "./reports/SeparationReport";
import ReportsChooser from "./ReportsChooser";
import MedicineConsumptionReport from "./reports/MedicineConsumptionReport";
import './_reports-view.scss';
import ReportsGenerate from "./ReportsGenerate";
import DispensersUsageReport from "./reports/DispensersUsageReport";
import ForageForAnimalReport from "./reports/forage-for-animal-report/ForageForAnimalReport";
import ForageUsePerDeviceReport from "./reports/forage-user-per-device-report/ForageUsePerDeviceReport";
import {reportType} from "../../constans/reports";
import {withTranslation} from "react-i18next";
import {getAllReports} from "../../actions/raportsActions";
import moment from "moment";
import {get} from "lodash";

export class ReportsView extends React.Component {

    constructor(props) {
        super(props);
        props.dispatch(getAllReports(props.farm));
    }

    select = (path, header, value) => {
        const {history} = this.props;
        if (path) {
            history.push(path);
        }
    };

    getHeaderName = () => {
        const {t} = this.props;
        try {
            const {location: {pathname}} = this.props;
            let split = pathname.slice(1).split("/");
            let key = pathname.includes("generate") ? split[3] : split[2];
            if (key) {
                let name = get(t(`newReports.headers`, {returnObjects: true}), key, t(`${key}`));
                let report = this.props.reports.find(item => item.FeturaQuery.FeturaQTime === +split[3]);
                if (report) {
                    return `${t('newReports.reportsView.report')}: ${name} (${moment.utc(report.FeturaQuery.QueryParams.startDate).format("DD.MM.YYYY")} - ${moment.utc(report.FeturaQuery.QueryParams.endDate).format("DD.MM.YYYY")})`
                }
                return `${t('newReports.reportsView.report')}: ${name}`
            }
            return t("reports");
        } catch (e) {
            console.error(e);
            return t("reports")
        }
    };

    render() {
        return (
            <div className="raports-view">
                <Header text={<>
                    {this.props.history.location.pathname !== `/${this.props.farm}/reports` &&
                    <Link to={`/${this.props.farm}/reports`}><i className={"fas fa-fw fa-arrow-left"}/></Link>}
                    {this.getHeaderName()}</>}/>
                <ViewContainer>
                    <Switch>
                        <Route path={`/${this.props.farm}/reports/selection/:id`} exact
                               render={props => <SelectionReport {...props}/>}/>
                        <Route path={`/${this.props.farm}/reports/sterilleDays/:id`} exact
                               render={props => <IdleDaysReport {...props}/>}/>
                        <Route path={`/${this.props.farm}/reports/sale/:id`} exact
                               render={props => <SalesReport {...props}/>}/>
                        <Route path={`/${this.props.farm}/reports/dead/:id`} exact
                               render={props => <DeadReport {...props}/>}/>
                        <Route path={`/${this.props.farm}/reports/treatment/:id`} exact
                               render={props => <TreatmentReport {...props}/>}/>
                        <Route path={`/${this.props.farm}/reports/birth/:id`} exact
                               render={props => <HistoryBirthReport {...props}/>}/>
                        <Route path={`/${this.props.farm}/reports/structure/:id`} exact
                               render={props => <HerdStructureReport {...props}/>}/>
                        <Route path={`/${this.props.farm}/reports/insemination/:id`} exact
                               render={props => <InseminationsReport {...props}/>}/>
                        <Route path={`/${this.props.farm}/reports/separation/:id`} exact
                               render={props => <SeparationReport {...props}/>}/>
                        <Route path={`/${this.props.farm}/reports/medicineConsumption/:id`} exact
                               render={props => <MedicineConsumptionReport {...props}/>}/>
                        <Route path={`/${this.props.farm}/reports/${reportType.DISPENSER_USAGE_Q03}/:id`} exact
                               render={props => <DispensersUsageReport {...props}/>}/>
                        <Route path={`/${this.props.farm}/reports/${reportType.DISPENSER_USAGE_Q51}/:id`} exact
                               render={props => <ForageForAnimalReport {...props}/>}/>
                        <Route path={`/${this.props.farm}/reports/${reportType.DISPENSER_USAGE_Q49}/:id`} exact
                               render={props => <ForageUsePerDeviceReport {...props}/>}/>
                        <Route path={`/${this.props.farm}/reports/generate/:type`} exact
                               render={props => <ReportsGenerate {...props}/>}/>
                        <Route render={props => <ReportsChooser {...props} onSelect={this.select}/>}/>
                    </Switch>
                </ViewContainer>
            </div>
        )
    }
}

ReportsView = connect(state => ({
    farm: state.location.farm,
    reports: state.raports.reports
}))(ReportsView);

export const _ReportsView = ReportsView;

ReportsView = withRouter(ReportsView);
export default withTranslation()(ReportsView);