import React, {Component} from "react";
import {connect} from "react-redux"
import {
    getAnimalTypeTranslation,
    getDateString,
    getGraftingReasonByID,
    getMedicineDoseByMedicineID,
    getMedicineNameByMedicineID,
    getOperatorName,
    getTreatmentTypeByIndex
} from "../../../utils/RaportsUtils";
import {convertWeightUnitTo, getUnit} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import TableGrid from "../../../components/basics/table-grid/TableGrid";
import {FilterTypes} from "../../../constans/filter";
import _ from "lodash";
import {sortAsNumber} from "../../../utils/SortUtils";
import Card from "../../../components/basics/card/Card";
import {withTranslation} from "react-i18next";
import {checkIfUserHasPrivilegedAccess} from "../../../utils/NewRolesUtils";

export class TreatmentReport extends Component {

    state = {
        data: []
    };

    constructor(props) {
        super(props);

        const {match: {params: {id}}} = this.props;
        let report = this.props.reports.find(item => item.FeturaQuery.FeturaQTime === +id);
        if (report) {
            this.state = {
                data: report.data
            }
        }
    }

    weightFormatter(value) {
        return _.isNil(value) ? undefined : convertWeightUnitTo(value, {
            showUnit: false,
            rawValue: true,
            unit: UnitTypes.MEDIUM,
            fixed: 1
        });
    };


    render() {
        const {t} = this.props;
        const {data} = this.state;
        const headers = [
            {
                name: t("animalNumber"),
                field: "anmNo",
                filterType: FilterTypes.STRING,
                customSort: sortAsNumber
            },
            {
                name: t("newReports.treatment.anmType"),
                valueFormatter: (value) => getAnimalTypeTranslation(value),
                excelFormatter: (value) => getAnimalTypeTranslation(value),
                field: "anmType",
                filterType: FilterTypes.STRING
            },
            {
                name: t("newReports.treatment.treatmentDate"),
                valueFormatter: (value) => getDateString(value),
                field: "treatmentDate",
                filterType: FilterTypes.DATE,
                disableValueFormatterSort: true
            },
            {
                name: t('newReports.treatment.reason'),
                valueFormatter: (value) => getGraftingReasonByID(value),
                excelFormatter: (value) => getGraftingReasonByID(value),
                field: "reason",
                filterType: FilterTypes.STRING
            },
            {
                name: t("newReports.treatment.who"),
                field: "who",
                filterType: FilterTypes.STRING,
                valueFormatter: (value) => getOperatorName(value),
                excelFormatter: (value) => getOperatorName(value),
                shouldShow: () => checkIfUserHasPrivilegedAccess(),
            },
            {
                name: t("newReports.treatment.treatmentType"),
                valueFormatter: value => getTreatmentTypeByIndex(value),
                excelFormatter: value => getTreatmentTypeByIndex(value),
                field: "treatmentType",
                filterType: FilterTypes.STRING
            },
            {
                name: t("medicine"),
                valueFormatter: (value) => getMedicineNameByMedicineID(value),
                excelFormatter: (value) => getMedicineNameByMedicineID(value),
                field: "medicine",
                filterType: FilterTypes.STRING
            },
            {
                name: t("dosage"),
                valueFormatter: value => getMedicineDoseByMedicineID(value),
                excelFormatter: value => getMedicineDoseByMedicineID(value),
                field: "dose",
                filterType: FilterTypes.STRING
            },
            {
                name: `${t("newReports.treatment.weight")} (${getUnit("weight", UnitTypes.MEDIUM)})`,
                valueFormatter: this.weightFormatter,
                excelFormatter: this.weightFormatter,
                field: "weight",
                filterType: FilterTypes.NUMBER
            }
        ];
        return (
            <Card>
                <TableGrid
                    data={data} headers={headers}
                    saveToExcel={true}
                    isSortable={true}
                    shouldIndex={true}
                    showFilter={true}
                    excelFileName={"Raport - Treatment"}
                    initialSortColumn={headers[0].name}

                />
            </Card>
        )
    }
}


TreatmentReport = connect((state) => ({
    farm: state.location.farm,
    reports: state.raports.reports
}))(TreatmentReport);
// TreatmentRaport = withRoles({roles: [Roles._BREEDING], showComponent: true})(TreatmentRaport);
export default withTranslation()(TreatmentReport);
