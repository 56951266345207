import React, {Component} from "react";
import {connect} from "react-redux"
import TableGrid from "../../../components/basics/table-grid/TableGrid";
import {convertWeightUnitTo, getUnit} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import {getAnimalTypeTranslation, getBuyerNameByID, getDateString, getPinnedRowSum} from "../../../utils/RaportsUtils";
import {FilterTypes} from "../../../constans/filter";
import _ from "lodash";
import {sortAsNumber} from "../../../utils/SortUtils";
import Card from "../../../components/basics/card/Card";
import {withTranslation} from "react-i18next";

export class SalesReport extends Component {

    state = {
        data: []
    };

    constructor(props) {
        super(props);

        const {match: {params: {id}}} = this.props;
        let report = this.props.reports.find(item => item.FeturaQuery.FeturaQTime === +id);
        if (report) {
            this.state = {
                data: report.data
            }
        }
    }

    weightFormatter = (value) => {
        return _.isNil(value) ? undefined : convertWeightUnitTo(value, {
            showUnit: false,
            unit: UnitTypes.MEDIUM,
            fixed: 1,
            rawValue: true
        });
    };

    render() {
        const {t} = this.props;
        const {data} = this.state;
        const headers = [
            {
                name: t("newReports.sales.pigNo"),
                field: "anmNo",
                filterType: FilterTypes.STRING,
                customSort: sortAsNumber

            },
            {
                name: t("animalType"),
                field: "anmType",
                valueFormatter: (value) => getAnimalTypeTranslation(value),
                excelFormatter: (value) => getAnimalTypeTranslation(value),
                filterType: FilterTypes.STRING

            },
            {
                name: t("saleDate"),
                valueFormatter: (value) => getDateString(value),
                excelFormatter: (value) => new Date(value),
                field: "saleDate",
                filterType: FilterTypes.DATE

            },
            {
                name: t("client"),
                field: "buyer",
                valueFormatter: (value) => getBuyerNameByID(value),
                excelFormatter: (value) => getBuyerNameByID(value),
                filterType: FilterTypes.STRING
            },
            {
                name: `${t("weight")} (${getUnit("weight", UnitTypes.MEDIUM)})`,
                field: "weight",
                valueFormatter: this.weightFormatter,
                excelFormatter: this.weightFormatter,
                filterType: FilterTypes.NUMBER
            },
            {
                name: t("price"),
                field: "price",
                valueFormatter: (value) => value ? value : undefined,
                excelFormatter: (value) => value ? value : undefined,
                filterType: FilterTypes.NUMBER
            }
        ];
        return (
            <Card>
                <TableGrid data={data} headers={headers}
                           bottomPinnedRows={(data) => {
                               return data.length ? [
                                   {
                                       price: getPinnedRowSum(data, "price"),
                                       weight: this.weightFormatter(getPinnedRowSum(data, "weight")),
                                       buyer: t("newReports.sales.total")
                                   }
                               ] : []
                           }}
                           saveToExcel={true}
                           isSortable={true}
                           shouldIndex={true}
                           showFilter={true}
                           excelFileName={"Raport - Sold"}
                           initialSortColumn={headers[0].name}
                />
            </Card>
        )
    }
}

SalesReport = connect((state) => ({
    farm: state.location.farm,
    reports: state.raports.reports
}))(SalesReport);

// SalesRaport = withRoles({roles: [Roles._BREEDING], showComponent: true})(SalesRaport);
export default withTranslation()(SalesReport);