import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./_forage-for-animal-full-width.scss"
import settingsDB from "../../../../database/settingsDB";
import {isUsingFakeData} from "../../../../utils/SettingsUtils";

class ForageForAnimalFullWidthRow extends Component {

    forageValueFormatter(value) {
        let forage = settingsDB.getSettingByID(value, {showDeleted: true});
        if (forage) return forage.SetData.Name;
        return "?"
    }

    render() {
        const {object: {anmid, forages, total_amount, total_amountofday}, animalValueFormatter, weightValueFormatter, rfidValueFormatter, index} = this.props;
        return (
            <div className="forage-for-animal-full-width">
                <div className="table-item index">{index}</div>
                <div className="table-item animal-number">{animalValueFormatter(anmid)}</div>
                <div className="table-item animal-number">{rfidValueFormatter(anmid)}</div>
                <div className="table-item forages-list">
                    {
                        isUsingFakeData() &&
                        <div className="forage-item" key={index}>
                            <div className="table-item forage-name">{forages}</div>
                            <div className="table-item">{total_amount}</div>
                            <div className="table-item">{total_amountofday}</div>
                        </div>
                    }
                    {
                        !isUsingFakeData() && forages.map((item, index) =>
                            <div className="forage-item" key={index}>
                                <div className="table-item forage-name">{this.forageValueFormatter(item.fid)}</div>
                                <div className="table-item">{weightValueFormatter(item.amount)}</div>
                                <div className="table-item">{item.amountofday}</div>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

ForageForAnimalFullWidthRow.propTypes = {
    animalValueFormatter: PropTypes.func.isRequired,
    weightValueFormatter: PropTypes.func.isRequired,
    rfidValueFormatter: PropTypes.func.isRequired,
};

export default ForageForAnimalFullWidthRow;