import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./_forage-use-per-device-full-width.scss"
import settingsDB from "../../../../database/settingsDB";
import {isUsingFakeData} from "../../../../utils/SettingsUtils";

class ForageUsePerDeviceFullWidthRow extends Component {

    forageValueFormatter(value) {
        let forage = settingsDB.getSettingByID(value, {showDeleted: true});
        if (forage) return forage.SetData.Name;
        return "?"
    }

    render() {
        const {index, object: {plid, devid, forages, total_workday, total_amount}, placeValueFormatter, deviceValueFormatter, weightValueFormatter} = this.props;
        return (
            <div className="forage-use-per-device-full-width">
                <div className="table-item index">{index}</div>
                <div className="table-item">{placeValueFormatter(plid)}</div>
                <div className="table-item">{deviceValueFormatter(devid)}</div>
                <div className="table-item forages-list">
                    {
                        isUsingFakeData() &&
                        <div className="forage-item" key={index}>
                            <div className="table-item forage-name">{forages}</div>
                            <div className="table-item">{total_amount}</div>
                            <div className="table-item">{total_workday}</div>
                        </div>
                    }
                    {
                        !isUsingFakeData() && forages.map((item, index) =>
                            <div className="forage-item" key={index}>
                                <div className="table-item forage-name">{this.forageValueFormatter(item.fid)}</div>
                                <div className="table-item">{weightValueFormatter(item.amount)}</div>
                                <div className="table-item">{item.workday}</div>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

ForageUsePerDeviceFullWidthRow.propTypes = {
    deviceValueFormatter: PropTypes.func.isRequired,
    weightValueFormatter: PropTypes.func.isRequired,
    placeValueFormatter: PropTypes.func.isRequired,
};

export default ForageUsePerDeviceFullWidthRow;