import React, {Component} from "react";
import {connect} from "react-redux"
import TableGrid from "../../../components/basics/table-grid/TableGrid";
import {getDateString} from "../../../utils/RaportsUtils";
import {FilterTypes} from "../../../constans/filter";
import _ from "lodash";
import {sortAsNumber} from "../../../utils/SortUtils";
import Card from "../../../components/basics/card/Card";
import {withTranslation} from "react-i18next";

export class DeadReport extends Component {

    state = {
        data: []
    };

    constructor(props) {
        super(props);

        const {match: {params: {id}}} = this.props;
        let report = this.props.reports.find(item => item.FeturaQuery.FeturaQTime === +id);
        if (report) {
            this.state = {
                data: report.data
            }
        }
    }

    render() {
        const {t} = this.props;
        const {data} = this.state;
        const headers = [
            {
                name: t("animalNumber"),
                field: "AnmNo1",
                filterType: FilterTypes.STRING,
                customSort: sortAsNumber
            },
            {
                name: t("newReports.dead.dthSellTime"),
                field: "DtaDthTime",
                valueFormatter: (value) => getDateString(value),
                excelFormatter: (value) => new Date(value),
                filterType: FilterTypes.DATE,
                disableValueFormatterSort: true
            }
        ];

        return (
            <Card>
                <TableGrid data={data} headers={headers}
                           bottomPinnedRows={(currentData) => {
                               return currentData.length ? [
                                   {
                                       AnmNo1: t("newReports.dead.total"),
                                       DtaDthTime: _.get(currentData, "length", 0)
                                   }] : []
                           }}
                           saveToExcel={true}
                           isSortable={true}
                           shouldIndex={true}
                           showFilter={true}
                           excelFileName={"Raport - Dead or Sold"}
                           initialSortColumn={headers[0].name}
                />
            </Card>
        )
    }
}

// DeadRaport = withRoles({roles: [Roles._BREEDING], showComponent: true})(DeadRaport);
DeadReport = connect((state) => ({
    language: state.language.lang,
    farm: state.location.farm,
    reports: state.raports.reports
}))(DeadReport);
export default withTranslation()(DeadReport);