import React, {Component} from 'react';
import {connect} from 'react-redux';
import LoadingComponent from "../../../../components/loading/LoadingComponent";
import InfoBox from "../../../../components/basics/info-box/InfoBox";
import TableGrid from "../../../../components/basics/table-grid/TableGrid";
import Card from "../../../../components/basics/card/Card";
import NotFound from "../../../../components/NotFound";
import {withReport} from "../withReport";
import devicesDB from "../../../../database/devicesDB";
import buildingsDB from "../../../../database/buildingsDB";
import {convertWeightUnitTo} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";
import {FilterTypes} from "../../../../constans/filter";
import settingsDB from "../../../../database/settingsDB";
import ForageUsePerDeviceFullWidthRow from "./ForageUsePerDeviceFullWidthRow";
import {withTranslation} from "react-i18next";
import {isUsingFakeData} from "../../../../utils/SettingsUtils";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
    };
}

class ForageUsePerDeviceReport extends Component {

    deviceValueFormatter = value => {
        if (isUsingFakeData()) return value;
        if (/^([a-z0-9-])*_\d{1,2}/gim.test(value)) {
            let split = value.split("_");
            let DevID = split[0], index = +split[1];
            let device = devicesDB.getDeviceByID(DevID);
            return `${device.Name} [${index + 1}]`;
        } else {
            let device = devicesDB.getDeviceByID(value);
            if (device) return device.Name;
        }
    };

    placeValueFormatter = value => {
        if (isUsingFakeData()) return value;
        if (value) {
            let text = [];
            for (let plcmnt of value) {
                let tree = buildingsDB.getTreeByLocationID(plcmnt);
                if (tree) {
                    if (tree.box) {
                        text.push(`${tree.chamber.CName} - ${tree.box.BoxesName}`);
                    } else {
                        text.push(`${tree.chamber.CName}`);
                    }
                }
            }
            return text.join(",");
        }
    };

    weightValueFormatter = value => {
        if (isUsingFakeData()) return value;
        if (value) return convertWeightUnitTo(+value, {
            unit: UnitTypes.MEDIUM,
            showUnit: true
        })
    };

    foragesValueFormatter = value => {
        if (isUsingFakeData()) return value;
        let names = value.map(item => {
            let forage = settingsDB.getSettingByID(item.fid, {showDeleted: true});
            if (forage) return forage.SetData.Name;
            return "?";
        });
        return names.join(" ");
    };

    bottomPinnedRows = (paginatedData) => {
        let forages = {};
        for (let row of paginatedData) {
            for (let forage of row.forages) {
                if (!forages[forage.fid]) forages[forage.fid] = {total_amount: 0, total_workday: 0};
                forages[forage.fid].total_amount += +forage.amount;
                forages[forage.fid].total_workday += +forage.workday;
            }
        }
        let totalAmount = 0;
        let totalWorkDay = 0;
        let array = Object.keys(forages).map((key, index) => {
            totalAmount += forages[key].total_amount;
            totalWorkDay += forages[key].total_workday;
            return {
                plid: index === 0 ? this.props.t("newReports.dispensersUsage.sum") : undefined,
                forages: this.foragesValueFormatter([{fid: key}]),
                ...forages[key],
                total_amount: this.weightValueFormatter(forages[key].total_amount)
            }
        });
        array.push({
            forages: this.props.t("all"),
            total_amount: this.weightValueFormatter(totalAmount),
            total_workday: totalWorkDay
        })
        return array;
    }

    render() {
        const {loading, report, error, data, t} = this.props;
        if (!report) return <Card><NotFound/></Card>;
        const headers = [
            {
                name: t("placement"),
                field: "plid",
                valueFormatter: this.placeValueFormatter,
                filterType: FilterTypes.LOCATION,
                disableValueFormatterFilter: true,
                excelFormatter: this.placeValueFormatter
            },
            {
                name: t("device"),
                field: "devid",
                valueFormatter: this.deviceValueFormatter,
                filterType: FilterTypes.STRING,
                excelFormatter: this.deviceValueFormatter,
            },
            {
                name: t("forage"),
                field: "forages",
                valueFormatter: this.foragesValueFormatter,
                filterType: FilterTypes.STRING,
                excelFormatter: this.foragesValueFormatter,
            },
            {
                name: t('forageAmount'),
                field: "total_amount",
                valueFormatter: this.weightValueFormatter,
                filterType: FilterTypes.NUMBER,
                excelFormatter: this.weightValueFormatter,
            },
            {
                name: t('numberOfDays'),
                field: "total_workday",
                filterType: FilterTypes.NUMBER
            }
        ];
        return (
            <Card style={loading ? {minHeight: "10rem"} : null}>
                <LoadingComponent isLoading={loading}/>
                {
                    error &&
                    <InfoBox boxColor={"error"}>
                        <div>
                            {t("newReports.error")}
                        </div>
                        <div>
                            {error}
                        </div>
                    </InfoBox>
                }
                {
                    !error &&
                    <>
                        <TableGrid data={data} headers={headers} shouldIndex showFilter
                                   fullWidthRow={<ForageUsePerDeviceFullWidthRow
                                       deviceValueFormatter={this.deviceValueFormatter}
                                       weightValueFormatter={this.weightValueFormatter}
                                       placeValueFormatter={this.placeValueFormatter}/>} isFullWidthRow={() => true}
                                   saveToExcel excelFileName={"forageUsePerDevice"}
                                   initialSortColumn={headers[0].name}
                                   bottomPinnedRows={!isUsingFakeData() && this.bottomPinnedRows}/>
                    </>
                }
            </Card>
        );
    }
}

ForageUsePerDeviceReport = withReport()(ForageUsePerDeviceReport);

ForageUsePerDeviceReport = connect(
    mapStateToProps,
)(ForageUsePerDeviceReport);

export default withTranslation()(ForageUsePerDeviceReport);
