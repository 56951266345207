import React from "react";
import Card from "../../../components/basics/card/Card";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import NotFound from "../../../components/NotFound";
import InfoBox from "../../../components/basics/info-box/InfoBox";
import TableGrid from "../../../components/basics/table-grid/TableGrid";
import devicesDB from "../../../database/devicesDB";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import buildingsDB from "../../../database/buildingsDB";
import {FilterTypes} from "../../../constans/filter";
import {withReport} from "./withReport";
import {withTranslation} from "react-i18next";
import {isUsingFakeData} from "../../../utils/SettingsUtils";

export class DispensersUsageReport extends React.Component {

    deviceValueFormatter = value => {
        if (isUsingFakeData()) return value;
        if (/^([a-z0-9-])*_\d{1,2}/gim.test(value)) {
            let split = value.split("_");
            let DevID = split[0], index = +split[1];
            let device = devicesDB.getDeviceByID(DevID);
            return `${device.Name} [${index + 1}]`;
        } else {
            let device = devicesDB.getDeviceByID(value);
            if (device) return device.Name;
        }
    };

    weightValueFormatter = value => {
        if (isUsingFakeData()) return value;
        if (value) return convertWeightUnitTo(+value, {
            unit: UnitTypes.MEDIUM,
            showUnit: true
        })
    };

    placeValueFormatter = value => {
        if (isUsingFakeData()) return value;
        if (value) {
            let text = [];
            for (let plcmnt of value) {
                let tree = buildingsDB.getTreeByLocationID(plcmnt);
                if (tree) {
                    if (tree.box) {
                        text.push(`${tree.chamber.CName} - ${tree.box.BoxesName}`);
                    } else {
                        text.push(`${tree.chamber.CName}`);
                    }
                }
            }
            return text.join(",");
        }
    };

    bottomPinnedRows = (paginatedData) => {
        let sumOfConsumption = paginatedData.reduce((a, b) => a + b.sumofconsumption, 0);
        let sumOfFeedings = paginatedData.reduce((a, b) => a + b.sumoffeedings, 0);
        return [{
            plid: this.props.t("newReports.dispensersUsage.sum"),
            sumofconsumption: this.weightValueFormatter(sumOfConsumption),
            sumoffeedings: this.weightValueFormatter(sumOfFeedings)
        }]
    }

    render() {
        const {loading, report, error, data, t} = this.props;
        const headers = [
            {
                name: t("placement"),
                field: "plid",
                valueFormatter: this.placeValueFormatter,
                filterType: FilterTypes.LOCATION,
                disableValueFormatterFilter: true,
                excelFormatter: this.placeValueFormatter,
            },
            {
                name: t("newReports.dispensersUsage.dispenser"),
                field: "devid",
                valueFormatter: this.deviceValueFormatter,
                filterType: FilterTypes.STRING,
                excelFormatter: this.deviceValueFormatter,
            },
            {
                name: t("newReports.dispensersUsage.planned"),
                field: "sumofconsumption",
                valueFormatter: this.weightValueFormatter,
                filterType: FilterTypes.NUMBER,
                excelFormatter: this.weightValueFormatter,
            },
            {
                name: t("newReports.dispensersUsage.usage"),
                field: "sumoffeedings",
                valueFormatter: this.weightValueFormatter,
                filterType: FilterTypes.NUMBER,
                excelFormatter: this.weightValueFormatter,
            }
        ];
        if (!report) return <Card><NotFound/></Card>;
        return (
            <Card style={loading ? {minHeight: "10rem"} : null}>
                <LoadingComponent isLoading={loading}/>
                {
                    error &&
                    <InfoBox boxColor={"error"}>
                        <div>
                            {t("newReports.error")}
                        </div>
                        <div>
                            {error}
                        </div>
                    </InfoBox>
                }
                {
                    !error &&
                    <>
                        <TableGrid data={data} headers={headers} shouldIndex showFilter
                                   initialSortColumn={headers[0].name}
                                   bottomPinnedRows={!isUsingFakeData() && this.bottomPinnedRows}
                                   saveToExcel excelFileName={"dispenserUsage"}/>
                    </>
                }
            </Card>
        )
    }
}

DispensersUsageReport = withTranslation()(DispensersUsageReport);
export default withReport()(DispensersUsageReport);
