import React, {Component} from 'react';
import {connect} from 'react-redux';
import Card from "../../components/basics/card/Card";
import {Col, Row} from "react-bootstrap";
import moment from "moment";
import Button from "../../components/basics/button/Button";
import "./_reports-view.scss"
import {
    createReportAthena,
    getDeadData,
    getHerdStructureData,
    getHistoryBirthData,
    getIdleDaysData,
    getInseminationsData,
    getMedicineConsumptionData,
    getSalesData,
    getSelectionData,
    getSeparationData,
    getTreatmentData
} from "../../actions/raportsActions";
import {reportType} from "../../constans/reports";
import {Field, reduxForm} from "redux-form";
import ReduxLabeledInput from "../../components/basics/input/labeled-input/ReduxLabeledInput";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        user: state.user.user,
    };
}

class ReportsGenerate extends Component {

    constructor(props) {
        super(props);

        this.props.initialize({
            dateFrom: moment.utc().startOf("day").subtract(1, "week"),
            dateTo: moment.utc().endOf("day"),
            forDay: moment.utc().endOf("day")
        })
    }

    getText(type) {
        // TODO trzeba napisać jakieś ładne teksty, które będą odzwierciedlały co znajduje się w raporcie
        const {t} = this.props;
        return t(`newReports.headers.${type}`);
    }

    renderForm(type) {
        //todo - zmienic tlumaczenie jak z aplikacji bedzie mozna tlumaczyc pliki na S3
        const {t} = this.props;
        if (type === reportType.STRUCTURE) {
            return (
                <Row>
                    <Col sm={6}>
                        <Field
                            name="forDay"
                            component={ReduxLabeledInput}
                            label={"Na dzień:"}
                            type={"date"}
                            parse={value => value ? moment.utc(value) : null}
                            format={value => value ? value.format(moment.HTML5_FMT.DATE) : null}
                        />
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row>
                    <Col sm={6}>
                        <Field
                            name="dateFrom"
                            component={ReduxLabeledInput}
                            label={t("dateFrom")}
                            type={"date"}
                            parse={value => value ? moment.utc(value) : null}
                            format={value => value ? value.format(moment.HTML5_FMT.DATE) : null}
                        />
                    </Col>
                    <Col sm={6}>
                        <Field
                            name="dateTo"
                            component={ReduxLabeledInput}
                            label={t("dateTo")}
                            type={"date"}
                            parse={value => value ? moment.utc(value) : null}
                            format={value => value ? value.format(moment.HTML5_FMT.DATE) : null}
                        />
                    </Col>
                </Row>
            );
        }
    }

    render() {
        const {match: {params: {type}}, t, handleSubmit} = this.props;
        return (
            <Card className="reports-generate">
                {this.getText(type)}
                <form onSubmit={handleSubmit}>
                    {this.renderForm(type)}
                    <Button buttonColor={"success"} icon={<i className="fas fa-file"/>}
                            onClick={this.generateReport}>{t("newReports.headers.generate")}</Button>
                </form>
            </Card>
        );
    }
}

function submit(values, dispatch, props) {
    const {match: {params: {type}}, farm, user} = props;
    const {dateTo, dateFrom, forDay} = values;
    dateTo.endOf("day");
    switch (type) {
        case reportType.BIRTH:
            dispatch(getHistoryBirthData(farm, dateFrom.toDate().getTime(), dateTo.toDate().getTime()));
            break;
        case reportType.IDLE:
            dispatch(getIdleDaysData(farm, dateFrom.toDate().getTime(), dateTo.toDate().getTime()));
            break;
        case reportType.INSEMINATION:
            dispatch(getInseminationsData(farm, dateFrom.toDate().getTime(), dateTo.toDate().getTime()));
            break;
        case reportType.SEPARATION:
            dispatch(getSeparationData(farm, dateFrom.toDate().getTime(), dateTo.toDate().getTime()));
            break;
        case reportType.STRUCTURE:
            dispatch(getHerdStructureData(farm, forDay.toDate().getTime()));
            break;
        case reportType.SELECTION:
            dispatch(getSelectionData(farm, dateFrom.toDate().getTime(), dateTo.toDate().getTime()));
            break;
        case reportType.DEAD:
            dispatch(getDeadData(farm, dateFrom.toDate().getTime(), dateTo.toDate().getTime()));
            break;
        case reportType.SALES:
            dispatch(getSalesData(farm, dateFrom.toDate().getTime(), dateTo.toDate().getTime()));
            break;
        case reportType.TREATMENT:
            dispatch(getTreatmentData(farm, dateFrom.toDate().getTime(), dateTo.toDate().getTime()));
            break;
        case reportType.MEDICINE_CONSUMPTION:
            dispatch(getMedicineConsumptionData(farm, dateFrom.toDate().getTime(), dateTo.toDate().getTime()));
            break;
        default:
            dispatch(createReportAthena(type, dateFrom.toDate().getTime(), dateTo.toDate().getTime(), [farm], user.ClientID, user.LocalUserID));
    }
    props.history.push(`/${farm}/reports`);
}

function validate(values, props) {
    const errors = {};
    const {dateTo, dateFrom} = values;
    if (!dateFrom) {
        errors.dateFrom = props.t("required");
    }

    if (!dateTo) {
        errors.dateTo = props.t("required");
    }

    if (dateFrom && dateTo && dateFrom.toDate().getTime() > dateTo.toDate().getTime()) {
        errors.dateFrom = props.t("errors.mustBeBelowOrEqualStartTime") + dateTo.format("DD.MM.YYYY");
    }

    return errors;
}

ReportsGenerate = reduxForm({
    form: "generateReport",
    onSubmit: submit,
    validate
})(ReportsGenerate);

ReportsGenerate = connect(
    mapStateToProps,
)(ReportsGenerate);

export default withTranslation()(ReportsGenerate);