import React, {Component} from "react";
import {connect} from "react-redux"
import {getDateString} from "../../../utils/RaportsUtils";
import TableGrid from "../../../components/basics/table-grid/TableGrid";
import {FilterTypes} from "../../../constans/filter";
import {sortAsNumber} from "../../../utils/SortUtils";
import Card from "../../../components/basics/card/Card";
import {withTranslation} from "react-i18next";

export class HistoryBirthReport extends Component {

    state = {
        data: []
    };

    constructor(props) {
        super(props);

        const {match: {params: {id}}} = this.props;
        let report = this.props.reports.find(item => item.FeturaQuery.FeturaQTime === +id);
        if (report) {
            this.state = {
                data: report.data
            }
        }
    }

    render() {
        const {t} = this.props;
        const {data} = this.state;
        const headers = [
            {
                name: t("newReports.birth.pigNo"),
                field: "AnmNo1",
                valueFormatter: (value) => value || "?",
                filterType: FilterTypes.NUMBER,
                customSort: sortAsNumber
            },
            {
                name: t("newReports.birth.parturitionDate"),
                field: "parturitionDate",
                valueFormatter: (value) => getDateString(value, {format: "DD-MM-YYYY"}),
                excelFormatter: (value) => new Date(value),
                filterType: FilterTypes.DATE,
                disableValueFormatterSort: true,
                disableValueFormatterFilter: true
            },
            {
                name: t("alive"),
                field: "HealthyCnt",
                filterType: FilterTypes.NUMBER,
            },
            {
                name: t("dead"),
                field: "DeadCnt",
                filterType: FilterTypes.NUMBER,
            },
            {
                name: t("mummies"),
                field: "MummyCnt",
                filterType: FilterTypes.NUMBER,
            },
            {
                name: t("avgLitterWeight"),
                field: "Weight",
                valueFormatter: (value) => value / 1000 + " kg",
                filterType: FilterTypes.NUMBER,
            }
        ];

        return (
            <Card>
                <TableGrid data={data} headers={headers}
                           saveToExcel={true}
                           isSortable={true}
                           shouldIndex={true}
                           showFilter={true}
                           excelFileName={"Raport - Parturitions"}
                           initialSortColumn={headers[0].name}
                />
            </Card>
        )
    }
}

HistoryBirthReport = connect((state) => ({
    farm: state.location.farm,
    reports: state.raports.reports
}))(HistoryBirthReport);
// ExpectingBirthRaport = withRoles({roles: [Roles._BREEDING], showComponent: true})(ExpectingBirthRaport);
export default withTranslation()(HistoryBirthReport);