import React from "react";
import ItemPicker from "../../components/basics/item-picker/ItemPicker";
import dead from "../../resources/images/raports/dead.svg";
import birth from "../../resources/images/raports/expecting-birth.svg";
import structure from "../../resources/images/raports/herd-structure.svg";
import idle from "../../resources/images/raports/idle-days.svg";
import sales from "../../resources/images/raports/sales.svg";
import selection from "../../resources/images/raports/selection.svg";
import separation from "../../resources/images/raports/separation.svg";
import treatment from "../../resources/images/raports/treatment.svg";
import insemination from "../../resources/images/raports/inseminations.svg";
import medicines from "../../resources/images/raports/medicines.svg";
import dispenserUsage from "../../resources/images/raports/dispenser_usage.svg";
import {connect} from "react-redux";
import Card from "../../components/basics/card/Card";
import TableGrid from "../../components/basics/table-grid/TableGrid";
import {reportStatus, reportType} from "../../constans/reports";
import moment from "moment";
import {getAllReports} from "../../actions/raportsActions";
import Tooltip from "../../components/basics/tooltip/Tooltip";
import reportsDB from "../../database/reportsDB";
import {show} from "redux-modal";
import {bindActionCreators} from "redux";
import {ModalName as ConfirmModalName} from "../../components/modals-new/confirm-modal/ConfirmModal";
import {
    deleteReport,
    deleteReportNotificationFailure,
    deleteReportNotificationSuccess
} from "../../api/reports/deleteReport";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import {get} from "lodash";

export default class ReportsChooser extends React.Component {

    state = {
        removing: []
    }

    onClick = value => {
        const {farm} = this.props;
        this.props.history.push(`/${farm}/reports/generate/${value}`);
    };

    formatDate = value => {
        return moment.utc(value).format("DD.MM.YYYY");
    };

    formatDates = value => {
        return this.formatDate(value.startDate) + " - " + this.formatDate(value.endDate);
    };

    getName = type => {
        const {t} = this.props;
        return get(t(`newReports.headers`, {returnObjects: true}), type, t(`${type}`)) || type;
    };


    onRowClick = (data) => {
        const {farm} = this.props;
        let status = data.QueryExecution.Status.State;
        if (this.state.removing.includes(data.FeturaQuery.FeturaQTime)) {
            status = reportStatus.REMOVING;
        }
        if (status === reportStatus.READY) {
            this.props.history.push(`/${farm}/reports/${data.FeturaQuery.QueryCode}/${data.FeturaQuery.FeturaQTime}`);
        }
    };

    onRemoveClick(e, props) {
        const {value} = props;
        const {show, t} = this.props;
        show(ConfirmModalName, {
            title: t("deleteReport"),
            text: i18next.t("newReports.headers.removeReportText", {
                type1: this.getName(value.FeturaQuery.QueryCode),
                type2: this.formatDates(value.FeturaQuery.QueryParams)
            }),
            confirmText: t("yes"),
            onConfirmed: p => {
                this.setState(state => {
                    const removing = state.removing.slice(0);
                    removing.push(value.FeturaQuery.FeturaQTime);
                    console.log(removing);
                    return {
                        removing
                    }
                })
                if (value.isLocal) {
                    reportsDB.removeReport(value);
                    this.props.dispatch(getAllReports(this.props.farm));
                } else {
                    deleteReport(value.LocalQTime).then(r => {
                        deleteReportNotificationSuccess(r);
                    }).catch(e => {
                        deleteReportNotificationFailure(e);
                        this.setState(state => {
                            const removing = state.removing.filter(item => item !== value.FeturaQuery.FeturaQTime);
                            return {
                                removing
                            }
                        })
                    })
                }
                p.handleHide();
            }
        })
        e.stopPropagation();
    }

    canRemoveReport(data) {
        let status = data.QueryExecution.Status.State;
        if (this.state.removing.includes(data.FeturaQuery.FeturaQTime)) {
            status = reportStatus.REMOVING;
        }
        return [
            reportStatus.READY,
            reportStatus.CANCELLED,
            reportStatus.FAILED,
            reportStatus.INVALID_QUERY_PARAMETERS,
            reportStatus.QUERY_EXECUTION_NOT_STARTED_FATAL
        ].includes(status);
    }

    render() {
        const {t, reports} = this.props;
        const headers = [
            {
                name: t("designation"),
                field: "FeturaQuery.QueryCode",
                valueFormatter: this.getName,
                itemClassName: "text-left"
            },
            {
                name: t("newReports.headers.dateRange"),
                field: "FeturaQuery.QueryParams",
                valueFormatter: this.formatDates
            },
            {
                name: t("newReports.headers.created"),
                field: "FeturaQuery.FeturaQTime",
                valueFormatter: (value) => moment(value).format("DD.MM.YYYY HH:mm")
            },
            {
                name: "",
                notSortable: true,
                field: "",
                component: props => {
                    console.log(props);
                    const {value} = props;
                    let status = value.QueryExecution.Status.State;
                    if (this.state.removing.includes(value.FeturaQuery.FeturaQTime)) {
                        status = reportStatus.REMOVING;
                    }
                    switch (status) {
                        case reportStatus.READY:
                            return <span><i className="fas fa-download"/> {t("newReports.headers.download")}</span>;
                        case reportStatus.QUERY_EXECUTION_NOT_STARTED_FATAL:
                        case reportStatus.INVALID_QUERY_PARAMETERS:
                        case reportStatus.FAILED:
                            return <span><i
                                className="fas fa-exclamation-circle"/> {t("newReports.headers.errorInGenerating")}</span>;
                        case reportStatus.CANCELLED:
                            return <span><i className="fas fa-times"/> {t('newReports.headers.canceled')}</span>;
                        case reportStatus.REMOVING:
                            return <span><i className="fas fa-circle-notch fa-spin"/> {t("newReports.headers.removing")}</span>;
                        case reportStatus.LOADING:
                        default:
                            return <Tooltip tooltipContent={t("newReports.headers.tooltipContent")}>
                                <span><i
                                    className="fas fa-circle-notch fa-spin"/> {t("newReports.headers.preparing")}</span>
                            </Tooltip>;
                    }
                }
            },
            {
                name: "",
                field: "",
                headerClassName: "index",
                itemClassName: "index",
                notSortable: true,
                component: (props) => this.canRemoveReport(props.value) ?
                    <i className="fas fa-trash pointer" onClick={e => this.onRemoveClick(e, props)}/> : null
            },
            {
                name: "",
                field: "QueryExecution.Status.State",
                headerClassName: "index",
                itemClassName: "index",
                notSortable: true,
                component: (props) => props.value === reportStatus.READY ? <i className="fas fa-chevron-right"/> : null
            }
        ];
        return (
            <>
                <Card>
                    <h4>{t("newReports.headers.generateReport")}</h4>
                    <ItemPicker value={null} options={[
                        {
                            label: t("newReports.headers.birth"),
                            value: reportType.BIRTH,
                            svgPath: birth
                        },
                        {
                            label: t("idle"),
                            value: reportType.IDLE,
                            svgPath: idle
                        },
                        {
                            label: t("newReports.headers.insemination"),
                            value: reportType.INSEMINATION,
                            svgPath: insemination
                        },
                        {
                            label: t("newReports.headers.separation"),
                            value: reportType.SEPARATION,
                            svgPath: separation
                        },
                        {
                            label: t("newReports.headers.structure"),
                            value: reportType.STRUCTURE,
                            svgPath: structure
                        },
                        {
                            label: t("newReports.headers.selection"),
                            value: reportType.SELECTION,
                            svgPath: selection
                        },
                        {
                            label: t("newReports.headers.dead"),
                            value: reportType.DEAD,
                            svgPath: dead
                        },
                        {
                            label: t("sale"),
                            value: reportType.SALES,
                            svgPath: sales
                        },
                        {
                            label: t("treatment"),
                            value: reportType.TREATMENT,
                            svgPath: treatment
                        },
                        {
                            label: t("newReports.headers.medicineConsumption"),
                            value: reportType.MEDICINE_CONSUMPTION,
                            svgPath: medicines
                        },
                        {
                            label: t("newReports.headers.Q03_sumoffeedings_by_devid"),
                            value: reportType.DISPENSER_USAGE_Q03,
                            svgPath: dispenserUsage
                        },
                        {
                            label: t("newReports.headers.Q49_forage_use_per_device"),
                            value: reportType.DISPENSER_USAGE_Q49,
                            svgPath: dispenserUsage
                        },
                        {
                            label: t("newReports.headers.Q51_feedings_for_animalid"),
                            value: reportType.DISPENSER_USAGE_Q51,
                            svgPath: dispenserUsage
                        }
                    ]} showLabels onChange={this.onClick}/>
                </Card>
                <Card>
                    <h4>{t("newReports.headers.generatedReports")}</h4>
                    <TableGrid data={reports} headers={headers} showPagination paginationItems={10}
                               onRowClick={this.onRowClick}/>
                </Card>
            </>
        );
    }

}

ReportsChooser = connect(
    state => ({
        farm: state.location.farm,
        reports: state.raports.reports
    }),
    dispatch => ({
        ...bindActionCreators({show}, dispatch),
        dispatch
    })
)(ReportsChooser);

ReportsChooser = withTranslation()(ReportsChooser);